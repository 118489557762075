
import { reactive, defineComponent, ref, computed, onMounted, onBeforeMount, } from "vue";
import ApiService from "@/core/services/ApiService";
import { showErrorPopup } from "@/core/helpers/common-helper";
import { numberFormat } from "@/core/helpers/currency-helper";
import moment from 'moment';
import { checkPermission } from "@/router/authentication";
import { useRouter } from "vue-router";

interface Account {
  account_number: string;
  dates: string[];
}

interface BankStatement {
  transactionDate?: string
  remark?: string
  type?: string
  amount?: string
  transactionDetailStatus?: string
}

export default defineComponent({
  setup() {
    const submitButton = ref<null | HTMLButtonElement>(null);
    const router = useRouter();

    const accountSuccess = ref(false);

    const data = ref()
    const isValidatingSource = ref(false);
    const sourceAccount = ref();

    const account = reactive<Account>({
      account_number: '',
      dates: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    });

    const submitAccount = () => {
      data.value = []

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const payload = {
        account_number: account.account_number,
        dates: account.dates
      };

      ApiService.setHeader();
      ApiService.post(
        `app/finance/account/statement`,
        payload as any
      )
        .then((response) => {
          accountSuccess.value = true;
          data.value = response.data.data
        })
        .catch(({ response }) => {
          account.account_number = '';

          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            if (response.data.data.length > 0) {
              for (const key in response.data.data) {
                showErrorPopup({
                  title: response.data.rc,
                  text: response.data.data[key],
                  message: response.data.data[key],
                })
              }
            } else {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.message,
                message: response.data.message,
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const clearAccount = () => {
      accountSuccess.value = false;
      account.account_number = '';
    }

    const dynamicInfo = computed(() => {
      if (!Object.prototype.hasOwnProperty.call(data.value, "detailData")) {
        return []
      }

      const bankStatements: BankStatement[] =  []

      data.value.detailData.forEach((i) => {
        const bankStatement: BankStatement = {}

        if (Object.prototype.hasOwnProperty.call(i, "transactionDate")) {
          bankStatement['transactionDate'] = i.transactionDate
        } else if (Object.prototype.hasOwnProperty.call(i, "dateTime")) {
          bankStatement['transactionDate'] = i.dateTime
        }

        if (Object.prototype.hasOwnProperty.call(i, "remark")) {
          bankStatement['remark'] = i.remark
        }

        if (Object.prototype.hasOwnProperty.call(i, "type")) {
          bankStatement['type'] = i.type
        } 

        if (Object.prototype.hasOwnProperty.call(i, "amount")) {
          console.log(i.amount.value)
          bankStatement['amount'] = i.amount.value
        } 

        if (Object.prototype.hasOwnProperty.call(i, "transactionDetailStatus")) {
          bankStatement['transactionDetailStatus'] = i.transactionDetailStatus
        } else if (Object.prototype.hasOwnProperty.call(i, "status")) {
          bankStatement['transactionDetailStatus'] = i.status
        } 

        bankStatements.push(bankStatement)

      })

      return bankStatements
    })

    const getSourceAccount = () => {
      isValidatingSource.value = true;

      ApiService.setHeader();
      ApiService.getWithoutSlug(
        `app/enum/channel-pool-account`,
        "",
        {}
      )
        .then((response) => {
          if (response.data.data.length > 0) {
            ApiService.getWithoutSlug(
              `app/enum/account`,
              "",
              {
                params: {
                  account_numbers: response.data.data,
                  type: 'internal'
                }
              }
            )
              .then((resp) => {
                sourceAccount.value = resp.data.data;
              })
              .finally(() => {
                isValidatingSource.value = false;
              });
          }
        })
        .catch(() => {
          isValidatingSource.value = false;
        });
    };

    onBeforeMount(() => {
      if (!checkPermission('account-statement').isGranted) {
        router.push({ name: "app" });
      }
    })

    onMounted(() => {
      getSourceAccount()
    })

    return {
      account,
      submitButton,
      accountSuccess,
      clearAccount,
      submitAccount,
      data,
      dynamicInfo,
      isValidatingSource,
      sourceAccount,
      numberFormat,
    }
  }
});
